
import {defineComponent, onMounted} from "vue";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import VisualEmbed from "@/views/quicksight/VisualEmbed.vue";

export default defineComponent( {
  name: "Dashboard",
  components: {VisualEmbed},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams('Team',
        [
          {link: false, router: '', text: 'Dashboard'}
        ]
      )
    })
  }
})
